<script>
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
</script>
<template>
  <div>
    <v-container>
      <v-card>
        <div class="wrapper px-2 justify-center">
          <div class="cols">
            <v-autocomplete
              prepend-inner-icon="mdi-magnify"
              hide-details
              clearable
              outlined
              @change="getStatistics"
              dense
              class="mr-1 pt-2"
              :items="references"
              item-text="name"
              item-value="id"
              v-model="sourceId"
              label="by Source"
            >
            </v-autocomplete>
          </div>
          <div class="cols">
            <v-select
              v-model="companyId"
              label="by Company"
              :items="companies"
              class="pt-2"
              prepend-inner-icon="mdi-briefcase-variant-outline"
              item-text="name"
              item-value="id"
              hide-details
              clearable
              outlined
              dense
              @change="getStatistics"
            />
          </div>
          <div class="cols">
            <v-select
              v-model="companyStateId"
              label="by Company State"
              class="mx-1 pt-2"
              :items="states"
              prepend-inner-icon="mdi-magnify"
              item-text="name"
              :loading="loadingStates"
              item-value="id"
              hide-details
              clearable
              outlined
              dense
              @change="getStatistics"
            />
          </div>

          <div class="cols">
            <ma-date-picker
              class="pt-2 pr-1"
              v-model="dateFrom"
              label="From"
              past
            />
          </div>
          <div class="cols">
            <ma-date-picker
              class="pt-2 pr-1"
              v-model="dateTo"
              label="To"
              past
            />
          </div>
          <div class="cols pb-2">
            <v-btn text dark color="indigo" @click="clearFilters()">
              <v-icon dark> mdi-broom </v-icon>clear filters
            </v-btn>
          </div>
          <div class="cols px-2 pb-2">
            <v-btn text dark color="indigo" @click="getStatistics()">
              <v-icon dark> mdi-update </v-icon>Update
            </v-btn>
          </div>
          <div class="cols pb-2">
            <template>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn
                    :loading="donwloading"
                    :disabled="donwloading"
                    v-on="on"
                    text
                    color="success"
                    @click="gotoExport()"
                    ><v-icon>mdi-file-excel</v-icon> Export</v-btn
                  >
                </template>
                <span>Export</span>
              </v-tooltip>
            </template>
          </div>
        </div>
        <template v-if="filterDate != 'clear'">
          <v-layout class="pa-4" column>
            <v-layout column class="text-body-1 text--secondary">
              <div>
                From
                <span class="text-caption">{{ prettyDate(query1.date1) }}</span>
              </div>
              <div>
                To
                <span class="text-caption">{{ prettyDate(query1.date2) }}</span>
              </div>
            </v-layout>
          </v-layout>
          <v-divider></v-divider>
        </template>
        <v-dialog
          v-model="dialogExport"
          persistent
          :overlay="false"
          max-width="800px"
          transition="dialog-transition"
        >
          <v-card>
            <v-card-title class="headline primary white--text">
              Export
            </v-card-title>
            <v-card-text class="mt-3">
              <div class="text-center">
                <h3>Select the columns you want to export:</h3>
              </div>
              <v-row no-gutters>
                <v-col cols="12" sm="3">
                  <v-checkbox
                    label="Select All"
                    v-model="checkAll"
                    color="success"
                    :value="true"
                  ></v-checkbox>
                </v-col>
              </v-row>
              <v-row no-gutters class="mb-4">
                <v-col
                  cols="12"
                  sm="3"
                  v-for="(check, i) in selectColumnExport"
                  :key="i"
                >
                  <v-switch
                    inset
                    :label="check == 'Company_State' ? 'Company State' : check"
                    v-model="checkToExport"
                    :value="check"
                  ></v-switch>
                </v-col>
              </v-row>
              <template v-if="donwloading">
                <v-alert dense outlined type="warning" class="text-center mt-4">
                  Exporting...Please wait!!
                </v-alert>
              </template>
            </v-card-text>
            <v-card-actions class="d-flex justify-end">
              <v-btn
                text
                :disabled="donwloading"
                color="error"
                @click="cancelExport()"
                >cancel</v-btn
              >
              <v-btn
                text
                :disabled="donwloading"
                :loading="donwloading"
                color="success"
                @click="confirmExport()"
                >export</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-data-table
          :headers="headers"
          :loading="loading"
          :items="statistics"
          :footer-props="footerProps"
          class="elevation-1"
        >
          <template v-slot:[`header.policy_new`]="{ header }">
            <template v-if="total && total.policy_new">
              {{ `${header.text}(${total.policy_new})` }}
            </template>
            <template v-else>
              {{ `${header.text}` }}
            </template>
          </template>
          <template v-slot:[`item.policy_new`]="{ item }">
            {{ item.policy_new || 0 }}
          </template>
          <template v-slot:[`item.policy_renewall`]="{ item }">
            {{ item.policy_renewall || 0 }}
          </template>
          <template v-slot:[`item.total`]="{ item }">
            {{ item.total || 0 }}
          </template>
          <template v-slot:[`item.policy_quoted`]="{ item }">
            {{ item.policy_quoted || 0 }}
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <template>
              <v-icon color="info" @click="showResumeSummary(item)"
                >mdi-eye</v-icon
              >
            </template>
          </template>

          <template v-slot:[`header.total`]="{ header }">
            <template v-if="total && total.total">
              {{ `${header.text}(${total.total})` }}
            </template>
            <template v-else>
              {{ `${header.text}` }}
            </template>
          </template>
          <template v-slot:[`header.policy_quoted`]="{ header }">
            <template v-if="total && total.policy_quoted">
              {{ `${header.text}(${total.policy_quoted})` }}
            </template>
            <template v-else>
              {{ `${header.text}` }}
            </template>
          </template>
          <template v-slot:[`header.policy_renewall`]="{ header }">
            <template v-if="total && total.policy_renewall">
              {{ `${header.text}(${total.policy_renewall})` }}
            </template>
            <template v-else>
              {{ `${header.text}` }}
            </template>
          </template>
        </v-data-table>
        <v-dialog
          v-model="showResume.dialog"
          :overlay="false"
          max-width="700px"
          transition="dialog-transition"
        >
          <v-card>
            <v-container>
              <template>
                <div class="text-center">Quote</div>
                <v-data-table
                  :headers="showResume.headers"
                  :items="showResume.quoted"
                  :items-per-page="5"
                  class="elevation-1"
                >
                  <template v-slot:[`item.polizeStatus`]="{ item }">
                    <div
                      class="font-weight-bold"
                      v-bind:style="{ color: colorStatus(item.polizeStatus) }"
                    >
                      {{ getStatusShow(item.polizeStatus) }}
                    </div>
                  </template>
                </v-data-table>
              </template>
              <template>
                <v-divider class="my-2"></v-divider>
                <div class="text-center">Renewals</div>
                <v-data-table
                  :headers="showResume.headers"
                  :items="showResume.renewal"
                  :items-per-page="5"
                  class="elevation-1"
                >
                  <template v-slot:[`item.polizeStatus`]="{ item }">
                    <div
                      class="font-weight-bold"
                      v-bind:style="{ color: colorStatus(item.polizeStatus) }"
                    >
                      {{ getStatusShow(item.polizeStatus) }}
                    </div>
                  </template>
                </v-data-table>
              </template>
              <template>
                <v-divider class="my-2"></v-divider>
                <div class="text-center">New</div>
                <v-data-table
                  :headers="showResume.headers"
                  :items="showResume.new"
                  :items-per-page="5"
                  class="elevation-1"
                >
                  <template v-slot:[`item.polizeStatus`]="{ item }">
                    <div
                      class="font-weight-bold"
                      v-bind:style="{ color: colorStatus(item.polizeStatus) }"
                    >
                      {{ getStatusShow(item.polizeStatus) }}
                    </div>
                  </template>
                </v-data-table>
              </template>
            </v-container>
          </v-card>
        </v-dialog>
      </v-card>
    </v-container>
  </div>
</template>
<script>
import { getAPI } from "@/api/axios-base";
import { notifyError } from "@/components/Notification";
import MaDatePicker from "@/components/MaDatePicker/MaDatePicker.vue";
import { PolicyStatus } from "@/utils/enums/policystatus";
import { mapActions, mapMutations, mapState } from "vuex";
import moment from "moment";
import { apiBaseUrl } from "@/enviorment";
import axios from "axios";
export default {
  components: {
    MaDatePicker,
  },
  data() {
    return {
      dateTo: null,
      dateFrom: null,
      filterDate: "clear",
      headers: [
        {
          text: "Agent",
          align: "start",
          sortable: true,
          value: "fullname",
        },
        {
          text: "Total",
          align: "center",
          sortable: true,
          value: "total",
        },
        {
          text: "Quote",
          align: "center",
          sortable: true,
          value: "policy_quoted",
        },
        /* {
          text: "Conversion %",
          align: "center",
          sortable: true,
          value: "conversion",
        }, */
        {
          text: "Renewals",
          align: "center",
          sortable: true,
          value: "policy_renewall",
        },
        {
          text: "New",
          align: "center",
          sortable: true,
          value: "policy_new",
        },
        {
          text: "",
          align: "center",
          sortable: true,
          value: "actions",
        },
      ],

      showResume: {
        dialog: false,
        quoted: [],
        renewal: [],
        new: [],
        headers: [
          { text: "Name", value: "fullname", sortable: false },
          { text: "Members", value: "member", sortable: false },
          { text: "Status", value: "polizeStatus", sortable: false },
        ],
      },
      query1: {
        date1: "",
        date2: "",
      },
      quoteDate: {
        date1: "",
        date2: "",
      },
      companyId: null,
      companyStateId: null,
      sourceId: null,
      interval: null,
      total: null,
      statistics: [],
      loading: false,
      dialogExport: false,
      toExport: null,
      donwloading: false,
      checkToExport: [],
      checkAll: false,
      selectColumnExport: [
        "AgentName",
        "Company",
        "CompanyState",
        "Fullname",
        "Members",
        "Status",
        "SourceName",
      ],
      footerProps: {
        "items-per-page-options": [15, 50, 100, 500],
      },
    };
  },
  computed: {
    ...mapState("crmSettingsModule", [
      "loadingRefer",
      "companies",
      "references",
    ]),
    ...mapState("crmMedicFormModule", ["states", "loadingStates"]),
  },
  watch: {
    dateFrom(val) {
      if (val != null && this.dateTo != null && this.dateTo != "") {
        this.filterDate = "custom";
        this.getStatistics();
      } else {
        this.filterDate = "clear";
      }
    },
    dateTo(val) {
      if (val != null && this.dateFrom != null && this.dateFrom != "") {
        this.filterDate = "custom";
        this.getStatistics();
      } else {
        this.filterDate = "clear";
      }
    },
  },
  methods: {
    ...mapMutations(["mutDisplaylogo"]),
    ...mapActions("crmMedicFormModule", ["actListStates"]),
    ...mapActions("crmSettingsModule", [
      "actListCompaniesNom",
      "actListReferences",
    ]),

    gotoExport() {
      this.dialogExport = true;
      this.checkToExport = [];
      this.checkAll = false;
    },
    cancelExport() {
      this.dialogExport = false;
      this.checkToExport = [];
      this.checkAll = false;
    },
    confirmExport() {
      const token = this.$store.state.accessToken;
      let auth =
        token != undefined && token != null && token != ""
          ? {
              Accept: "*/*",
              Authorization: `Bearer ${token}`,
            }
          : {
              Accept: "*/*",
            };
      const date = moment().format("MM/DD/YYYY HH:mm");

      const name = "Report-" + date + ".xlsx";
      this.donwloading = true;
      let body = Object.assign({}, this.toExport);
      if (this.checkToExport.length != 0) {
        body = { ...body, exportOnly: this.checkToExport };
      }

      const url = apiBaseUrl + "/reports/quoted-client-agent?export=true";
      axios({
        url: url,
        method: "POST",
        responseType: "blob",
        headers: auth,
        data: body,
      })
        .then((res) => {
          this.donwloading = false;
          const file_path = URL.createObjectURL(res.data);
          const a = document.createElement("A");
          a.href = file_path;
          a.setAttribute("download", name);
          a.setAttribute("target", "_blank");
          a.click();
          this.cancelExport();
        })
        .catch((err) => {
          this.donwloading = false;
          let mess = error.response.data.message.toString();

          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },
    showResumeSummary(item) {
      this.showResume.quoted = item.clients_quoted ? item.clients_quoted : [];
      this.showResume.renewal = item.clients_renewall
        ? item.clients_renewall
        : [];
      this.showResume.new = item.clients_new ? item.clients_new : [];
      this.showResume.dialog = true;
    },
    getStatistics() {
      this.loading = true;

      let body = {};
      if (this.filterDate == "custom") {
        this.quoteDate = {
          date1: moment(this.dateFrom).startOf("day").toISOString(),
          date2: moment(this.dateTo).endOf("day").toISOString(),
        };
        this.query1 = this.quoteDate;
        body = { ...body, quoteDate: this.quoteDate };
      }
      if (this.companyId) {
        body = { ...body, companyId: this.companyId };
      }
      if (this.sourceId) {
        body = { ...body, sourceId: this.sourceId };
      }
      if (this.companyStateId) {
        body = { ...body, companyStateId: this.companyStateId };
      }
      this.toExport = body;
      getAPI
        .post("/reports/quoted-client-agent", body)
        .then((res) => {
          this.loading = false;
          const response = res.data;
          this.statistics = response.filter((r) => r.fullname != "TOTAL");
          this.total = response.find((r) => r.fullname == "TOTAL");
        })
        .catch((error) => {
          let mess = error.response.data.message.toString();
          this.loading = false;

          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },
    clearFilters() {
      this.dateFrom = null;
      this.dateTo = null;
      this.filterDate = "clear";
      this.sourceId = null;
      this.companyId = null;
      this.companyStateId = null;
      this.getStatistics();
    },
    tryRequest() {
      this.interval = setInterval(this.getStatistics, 80000);
    },

    prettyDate(date) {
      if (date === null || date === "") return "";

      return moment(date).format("dddd D MMMM YYYY, h:mm:ss a");
    },

    getStatusShow(value) {
      if (value) {
        const st = PolicyStatus[value];
        return st;
      }

      return "-";
    },

    colorStatus(value) {
      if (value != null && value != undefined) {
        switch (value) {
          case "POLIZE_PENDING":
            return "#1663E0FF";
          case "PAYMENT_PENDING":
            return "#4B7ECE";
          case "POLIZE_OK":
            return "#19FF00FF";
          case "POLICY_NEW":
            return "#80FF00";
          case "PAYMENT_OK":
            return "#00FFB3";
          case "POLIZE_MEMBERS_NO_MATCH":
            return "#FF0033";
          case "PAYMENT_MEMBERS_NO_MATCH":
            return "#FFAA00";
          case "POLICY_CANCELLATION":
            return "#FF4400";
          case "DELINCUENT":
            return "#DE7954";
          case "POLICY_RECONCILIATION":
            return "#B7FF00";
          case "POLICY_RENEWAL":
            return "#EEC167";
          case "OTHER_PARTY":
            return "#9C27B0";
          case "NO_AOR":
            return "#625F62";
          case "NO_AOR_ACTIVE":
            return "#8e3bed";
          case "POLICY_BLACKLIST":
            return "#270E41";
          default:
            return "#000";
        }
      }
      return "#000";
    },
  },
  mounted() {
    this.mutDisplaylogo(true);
    this.tryRequest();
    this.actListStates();
    this.actListCompaniesNom();
    this.actListReferences();
    this.getStatistics();
  },
  destroyed() {
    this.mutDisplaylogo(false);
    clearInterval(this.interval);
  },
};
</script>
<style lang="scss" scoped>
.logo {
  z-index: 999;
}
.cols {
  width: 180px !important;
}
.wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 0px;
}
</style>
